.error404 {
	background-color: var(--black);
	color: var(--white);
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 30px;
	padding: 0 10px;

	&-title {
		margin: 0;
		font-weight: 700;
		margin-bottom: 35px;
		font-family: var(--oswald);
	}

	&-text {
		font-size: 18px;
		line-height: 25px;
		margin-bottom: 60px;
	}

	&-svg {
		margin: 0 auto;
		width: 300px;
		height: 300px;

		svg {
			width: 100%;
			height: 100%;
		}
	}
}

@media (--media-desktop) {
	.error404 {
		&-title {
			font-size: 40px;
		}

		&-text {
			font-size: 25px;
			line-height: 40px;
			width: 40%;
		}
	}
}
